import React from "react"
import loadable from "@loadable/component"
const SimpleBanner = loadable(() => import("./SimpleBanner"))
const BannerContactForm = loadable(() => import("./BannerContactForm"))

export const Index = ({ CommonTitleSubtitleBlock, index, theme }) => {
  const { contactForm } = CommonTitleSubtitleBlock
  switch (contactForm) {
    case "No":
      return <SimpleBanner {...CommonTitleSubtitleBlock} theme={theme} index={index} />
    case "Yes":
      return <BannerContactForm {...CommonTitleSubtitleBlock} />
    default:
      break
  }
}

export default Index
